<template>
  <div>
    <section class="white">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
              class="mb-4"
            >
              Απωλεία ή κλοπή διπλώματος
            </h2>

            <br>
            <p class="subtitle-1">
              Αρμόδια Υπηρεσία:<br><br>
              Η Υπηρεσία Μεταφορών & Επικοινωνιών της Περιφερειακής ενότητας ή του Περιφερειακού διαμερίσματος της Περιφέρειας
              του τόπου κατοικίας του ενδιαφερόμενου.<br><br>
              Προϋποθέσεις:<br><br>
              Σε περίπτωση που ο αιτών δεν είναι Έλληνας υπήκοος προσκομίζεται φωτοαντίγραφο της άδειας διαμονής ή παραμονής,
              ή άδειας εργασίας, η οποία πρέπει να έχει εκδοθεί έξι (6) μήνες πριν την υποβολή της αίτησης για άδεια οδήγησης.<br><br>
            </p>
            <v-btn
              color="success"
              class="px-5"
              large
            >
              Επικοινωνηστε τωρα
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <v-card>
              <v-img src="/static/sideServices/Χορήγηση Νέας Άδειας (κλοπήαπώλεια).jpg" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <p class="display-1">
          ΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ<br><br>
        </p><p class="subtitle-1">
          <ul>
            <li>Ταυτότητα ή ελληνικό διαβατήριο σε ισχύ.</li>
            <li>ΑΦΜ (αν δεν υπάρχει πρέπει να εκδοθεί από την εφορία.</li>
            <li>Δύο (2) έγχρωμες φωτογραφίες διαβατηρίου</li>
            <li>Βεβαίωση από την αστυνομία με τη δήλωση της απώλειας ή κλοπής.</li>
            <li>Παράβολο απώλειας των 30€ κωδ.(0029) </li>
            <li>Παράβολο εκτύπωσης των 30€ κωδ.(0028)</li>
            <li>Παράβολο χαρτόσημο των 9.02€ κωδ.(0066)</li>
            <li>Υπεύθυνη δήλωση και αίτηση εκτύπωσης (τις προμηθεύεστε από τη σχολή ή τις κατεβάζετε από το site)</li>
            <br>
          </ul>
        </p>
      </v-container>
    </section>

    <section class="grey lighten-3 pt-0">
      <v-container>
        <div class="text-center font-weight-bold">
          <v-text>
            Τα παράβολα εκδίδονται απο την εφορία ή ηλεκτρονικά απο το <a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-paravolo</a> και τα αναλαμβάνει και η σχολή να τα εκδώσει χωρίς επιβάρυνση!
          </v-text>
        </div>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col
            v-for="([name, path], i) in pdfs"
            :key="i"
            cols="6"
            md="6"
          >
            <div class="text-center">
              <div
                class="headline font-weight-bold mb-3"
                v-text="name"
              />
              <v-btn
                color="success"
                class="px-5"
                large
                @click="readFile(path)"
              >
                ΛΗΨΗ
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      pdfs: [
        ['Εκτύπωση Αίτησης', '../static/εκτύπωση-αίτησης.pdf'],
        ['Χορήγηση Άδειας λόγω Κλοπής ή Απώλειας', '../static/χορήγηση-αδειας-λόγω-κλοπής-ΜΤΑΟ-14.pdf']
      ]
    }),

    methods: {
      readFile (path) {
        window.open(path, '_blank')
      }
    }
  }
</script>
